// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qW d_gv d_cs";
export var heroHeaderCenter = "p_gw d_gw d_cs d_dw";
export var heroHeaderRight = "p_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "p_qX d_gr d_cw";
export var heroParagraphCenter = "p_gs d_gs d_cw d_dw";
export var heroParagraphRight = "p_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "p_qY d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "p_qZ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "p_q0 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "p_q1 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "p_q2 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "p_q3 s_q3";
export var heroExceptionNormal = "p_q4 s_q4";
export var heroExceptionLarge = "p_q5 s_q5";
export var Title1Small = "p_q6 s_q6 s_rp s_rq";
export var Title1Normal = "p_q7 s_q7 s_rp s_rr";
export var Title1Large = "p_q8 s_q8 s_rp s_rs";
export var BodySmall = "p_q9 s_q9 s_rp s_rJ";
export var BodyNormal = "p_rb s_rb s_rp s_rK";
export var BodyLarge = "p_rc s_rc s_rp s_rL";