// extracted by mini-css-extract-plugin
export var carouselContainer = "x_qh d_w d_H d_bf d_Z";
export var carouselContainerCards = "x_tT x_qh d_w d_H d_bf d_Z";
export var carouselContainerSides = "x_tV d_w d_H d_Z";
export var prevCarouselItem = "x_tW d_w d_H d_0 d_k";
export var prevCarouselItemL = "x_tX x_tW d_w d_H d_0 d_k";
export var moveInFromLeft = "x_tY";
export var prevCarouselItemR = "x_tZ x_tW d_w d_H d_0 d_k";
export var moveInFromRight = "x_t0";
export var selectedCarouselItem = "x_t1 d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "x_t2 x_t1 d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "x_t3 x_t1 d_w d_H d_Z d_bf";
export var nextCarouselItem = "x_t4 d_w d_H d_0 d_k";
export var nextCarouselItemL = "x_t5 x_t4 d_w d_H d_0 d_k";
export var nextCarouselItemR = "x_t6 x_t4 d_w d_H d_0 d_k";
export var arrowContainer = "x_t7 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "x_t8 x_t7 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "x_t9 x_t8 x_t7 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "x_vb x_t7 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "x_vc";
export var nextArrowContainerHidden = "x_vd x_vb x_t7 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "x_kG d_0";
export var prevArrow = "x_vf x_kG d_0";
export var nextArrow = "x_vg x_kG d_0";
export var carouselIndicatorContainer = "x_vh d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "x_vj d_w d_bz d_bF";
export var carouselText = "x_vk d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "x_vl x_vk d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "x_vm d_b7";
export var carouselIndicator = "x_vn x_vm d_b7";
export var carouselIndicatorSelected = "x_vp x_vm d_b7";
export var arrowsContainerTopRight = "x_vq d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "x_vr d_0 d_bl d_bC";
export var arrowsContainerSides = "x_vs d_0 d_bl d_bC";
export var smallArrowsBase = "x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "x_vv x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "x_vw x_vv x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "x_vx x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "x_vy x_vx x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "x_p7";
export var iconSmall = "x_vz";
export var multipleWrapper = "x_vB d_bC d_bF d_bf";
export var multipleImage = "x_vC d_bC";
export var sidesPrevContainer = "x_vD x_vv x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "x_vF x_vv x_vt d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";