// extracted by mini-css-extract-plugin
export var alignLeft = "q_qs d_bG";
export var alignCenter = "q_bP d_bD";
export var alignRight = "q_qw d_bH";
export var textAlignLeft = "q_rd";
export var textAlignCenter = "q_rf";
export var textAlignRight = "q_rg";
export var hoursInnerWrapperAlt = "q_rh d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "q_rj d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "q_rk d_dw";
export var titleMargin = "q_rl d_cw";
export var hoursInnerInnerWrapper = "q_rm d_cz";