// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_vG";
export var lbContainerInner = "y_vH";
export var movingForwards = "y_vJ";
export var movingForwardsOther = "y_vK";
export var movingBackwards = "y_vL";
export var movingBackwardsOther = "y_vM";
export var lbImage = "y_vN";
export var lbOtherImage = "y_vP";
export var prevButton = "y_vQ";
export var nextButton = "y_vR";
export var closing = "y_vS";
export var appear = "y_vT";